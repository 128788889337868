import React from 'react';
import { itemActions } from 'photography-client-lib/dist/src/item/itemActions';
import TPADimensionsHelper from '@wix/pro-gallery-tpa-wrapper/dist/src/helpers/TPADimensionsHelper';

import { SENTRY_DSN } from '../../constants';

// import prependHttpExtra from 'prepend-http-extra';
// import PropTypes from 'prop-types';
// import { translate } from 'react-i18next';
// import s from './GalleryWrapper.scss';
import Loadable from 'react-loadable';
import { ProGallery } from 'pro-gallery-renderer';
import { cssScrollHelper } from 'pro-gallery-renderer/dist/src/components/helpers/cssScrollHelper';
import { getProGalleryStyles } from 'photography-client-lib/dist/src/utils/proGalleryStyleBuilder';
import window from 'photography-client-lib/dist/src/sdk/windowWrapper';
import Consts from 'photography-client-lib/dist/src/utils/consts';
import experiments, {
  experimentsWrapper,
} from 'photography-client-lib/dist/src/sdk/experimentsWrapper';
// import { sentryUtils } from 'photography-client-lib/dist/src/utils/sentryUtils';
import './WixStyles.scss';
import './GalleryWrapper.scss';
import { utils } from '@wix/pro-gallery-tpa-wrapper/dist/src/utils';
import translationUtils from 'photography-client-lib/dist/src/utils/translationUtils';
import { pgVersionManager } from 'photography-client-lib/dist/src/versioning/proGalleryVersionManager';
import { parseStyleParams } from 'photography-client-lib/dist/src/fullscreen/parseStyleParams.js';

const PgContext = React.createContext({});

export default class ProGallerySantaWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreenIdx: -1,
    };
    this.getScrollingElement = this.getScrollingElement.bind(this);
    this.animatedOpenFullscreen = this.animatedOpenFullscreen.bind(this);
    this.animatedCloseFullscreen = this.animatedCloseFullscreen.bind(this);
    this.onAppLoaded = this.onAppLoaded.bind(this);

    this.pgTiming = { [Date.now()]: 'Wrapper Constructed' };
    this.onNewProps(props);
  }

  componentDidMount() {
    // this.pgTiming.wrapperMounted = Date.now();
    this.pgTiming[Date.now()] = 'Wrapper Mounted';
    const {
      galleryId,
      styleId,
      pageId,
      id,
      baseUrl,
      fullscreenUrl,
      instanceId,
      loadFonts,
      style,
    } = this.props;

    this.loadUserFonts(loadFonts, style.styleParams);
    itemActions.initWidgetData({
      compId: id,
      pageId,
      styleId,
      galleryId,
      baseUrl,
      fullscreenUrl,
      instanceId,
    });

    this.onNewProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.onNewProps(props);
  }

  loadUserFonts(loadFonts, styleParams) {
    try {
      const fontFamilies = Object.values(styleParams.fonts)
        .map(font => font.family)
        .filter(family => !!family)
        .join(',')
        .split(',');
      loadFonts(fontFamilies);
    } catch (e) {
      console.warn('Failed loading custom fonts');
    }
  }

  onNewProps(props) {
    Object.assign(this.pgTiming, props.pgTiming);
    this.updateVersionManagerIfNeeded(props);
    translationUtils.setTranslations(props.translations);
    experimentsWrapper.setExperiments({
      ...props.experiments,
      'specs.pro-gallery.newGalleryContainer': 'true',
      'specs.pro-gallery.useRefactoredProGallery': 'true',
      'specs.pro-gallery.itemsPositioning': 'absolute',
    });
    //todo- remove this after fixing experiment (all modules must use the same instance of photography-client-lib)
    window.petri = {
      'specs.pro-gallery.newGalleryContainer': 'true',
      'specs.pro-gallery.useRefactoredProGallery': 'true',
      'specs.pro-gallery.itemsPositioning': 'absolute',
    };
    window.isAccessibilityEnabled = props.accessibilityEnabled;

    if (window.isSSR) {
      const isMobile = this.isMobileDevice(props.formFactor);
      const deviceType = isMobile ? 'mobile' : 'desktop';
      window.deviceType = isMobile ? 'mobile' : 'desktop';
      utils.setIsWixMobile(isMobile);
    }
  }

  captureMessage(str) {
    const options = {
      dsn: SENTRY_DSN,
      config: { environment: 'Native Component' },
    };

    try {
      const { raven } = this.props;
      raven.config(options.dsn, options.config);
      raven.captureMessage(str);
    } catch (e) {
      //
    }
  }

  onAppLoaded = () => {
    try {
      if (!this.appLoadedReported) {
        if (typeof this.props.onAppLoaded === 'function') {
          this.props.onAppLoaded();
          this.appLoadedReported = true;
        } else {
          const err =
            'Cannot report AppLoaded, onAppLoaded function is not defined';
          console.error(err);
          this.captureMessage(err);
        }
      }
      if (!this.appLoadBiReported) {
        if (
          this.props.appLoadBI &&
          typeof this.props.appLoadBI.loaded === 'function'
        ) {
          this.props.appLoadBI.loaded();
          this.appLoadBiReported = true;
        } else {
          const err =
            'Cannot report AppLoaded, appLoadBI.loaded function is not defined';
          console.error(err);
          this.captureMessage(err);
        }
      }
      if (utils.isVerbose()) {
        this.pgTiming[Date.now()] = 'Wrapper Render End';
        const pgTableStr = this.calcPgTimings();
        if (console.table) {
          console.table(pgTableStr);
        } else {
          console.log('Pro Gallery Timings');
          console.log(pgTableStr);
        }
      }
    } catch (e) {
      console.error('Could not report appLoaded', e);
    }
  };

  onItemClicked(item, styleParams) {
    if (styleParams.itemClick !== 'expand') {
      this.props.onItemClicked(item);
    } else {
      this.animatedOpenFullscreen(item, styleParams);
    }
  }

  animatedCloseFullscreen(itemIdx) {
    //scroll to the item
    let y;
    try {
      if (itemIdx >= 0) {
        const itemDto = this.props.items[itemIdx];
        const item = { id: itemDto.itemId };
        const itemDomId = cssScrollHelper.getDomId(item);
        const rect = window.document
          .getElementById(itemDomId)
          .getBoundingClientRect();
        const padding = (window.innerHeight - rect.height) / 2;
        y = window.scrollY + rect.y - padding;
        if (y >= 0) {
          this.props.scrollTo(0, y);
        }
      }
    } catch (e) {
      console.warn('Could find last fullscreen item', itemIdx, e);
    }

    //close the fullscreen
    // this.setState({
    //   fullscreenIdx: -1,
    // });

    // animate close
    this.setState(
      {
        fullscreenAnimating: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            fullscreenIdx: -1,
          });
        }, 800);
      },
    );
  }

  animatedOpenFullscreen(item, styleParams) {
    const expandAnimation = Consts.expandAnimations.FADE_IN; // styleParams.expandAnimation

    if (
      expandAnimation === Consts.expandAnimations.EXPAND ||
      expandAnimation === Consts.expandAnimations.ZOOM
    ) {
      const itemDom = window.document.querySelector(
        `#${cssScrollHelper.getDomId(item)}`,
      );
      const itemDomHover = window.document.querySelector(
        `#${cssScrollHelper.getDomId(item)} .gallery-item-hover`,
      );
      if (!itemDom) return;
      let rect;
      let origFsItemStyle;
      try {
        rect = itemDom.getBoundingClientRect();
        origFsItemStyle = Object.assign({}, itemDom.style);
        Object.assign(itemDomHover.style, {
          visibility: 'hidden',
        });
        if (expandAnimation === Consts.expandAnimations.EXPAND) {
          Object.assign(itemDom.style, {
            zIndex: 9999999,
            position: 'fixed',
            transition: 'none',
            top: 0,
            left: 0,
            transform: `translate3d(${rect.left}px, ${rect.top}px, 0)`,
            width: rect.width + 'px',
            height: rect.height + 'px',
          });
        }
      } catch (e) {
        //
      }
      this.setState(
        {
          fullscreenAnimating: true,
          fullscreenIdx: item.idx,
        },
        () => {
          try {
            const body = window.document.querySelector(
              `#pro-gallery-${this.props.id}`,
            );
            const origBodyStyle = Object.assign({}, body.style);
            const fsItemDom = window.document.querySelector(
              '.fullscreen-current .fullscreen-content',
            );
            const fsRect = fsItemDom.getBoundingClientRect();
            const scale = Math.min(
              fsRect.width / rect.width,
              fsRect.height / rect.height,
            );
            const dY = Math.round(
              fsRect.top + fsRect.height / 2 - (rect.top + rect.height / 2),
            );
            const dX = Math.round(
              fsRect.left + fsRect.width / 2 - (rect.left + rect.width / 2),
            );
            if (expandAnimation === Consts.expandAnimations.EXPAND) {
              Object.assign(itemDom.style, {
                zIndex: 9999999,
                position: 'fixed',
                transform: `translate3d(${rect.left + dX}px,${rect.top +
                  dY}px, 0) scale(${scale})`,
                filter: 'opacity(0)',
                transition: 'transform .4s ease, filter 1.5s ease',
              });
            } else if (expandAnimation === Consts.expandAnimations.ZOOM) {
              Object.assign(body.style, {
                transform: `translate3d(${dX}px,${dY}px, 0) scale(${scale})`,
                // filter: 'opacity(0)',
                transition: 'transform .4s ease, filter 1.5s ease',
              });
            }
            this.setState({
              fullscreenAnimating: false,
            });
            setTimeout(() => {
              if (expandAnimation === Consts.expandAnimations.EXPAND) {
                itemDom.style.top = origFsItemStyle.top;
                itemDom.style.left = origFsItemStyle.left;
                itemDom.style.width = origFsItemStyle.width;
                itemDom.style.height = origFsItemStyle.height;
                itemDom.style.zIndex = origFsItemStyle.zIndex;
                itemDom.style.filter = origFsItemStyle.filter;
                itemDom.style.position = origFsItemStyle.position;
                itemDom.style.transform = origFsItemStyle.transform;
                itemDom.style.transition = origFsItemStyle.transition;
              } else if (expandAnimation === Consts.expandAnimations.ZOOM) {
                body.style.filter = origBodyStyle.filter;
                body.style.position = origBodyStyle.position;
                body.style.transform = origBodyStyle.transform;
                body.style.transition = origBodyStyle.transition;
              }
              itemDomHover.style.visibility = 'visible';
            }, 2000);
          } catch (e) {
            //
          }
        },
      );
    }

    if (expandAnimation === Consts.expandAnimations.FADE_IN) {
      this.setState(
        {
          fullscreenAnimating: true,
          fullscreenIdx: item.idx,
        },
        () => {
          this.setState({
            fullscreenAnimating: false,
          });
        },
      );
    } else {
      this.setState({
        fullscreenAnimating: false,
        fullscreenIdx: item.idx,
      });
    }
  }

  getScrollingElement() {
    if (typeof this.scrollingElement === 'object') {
      return this.scrollingElement;
    } else if (typeof this.props.registerToScroll === 'function') {
      this.scrollingElement = {
        addEventListener: (eventName, callback) => {
          this.props.registerToScroll(callback);
        },
        removeEventListener: () => {},
        scrollTo: this.props.scrollTo,
      };
      return this.scrollingElement;
    } else {
      return {
        addEventListener: () => {},
        removeEventListener: () => {},
        scrollTo: this.props.scrollTo,
      };
    }
  }

  updateVersionManagerIfNeeded(props) {
    try {
      const { dateCreated, gallerySettings } = props;
      const _dateCreated = Date.parse(dateCreated);
      if (pgVersionManager.dateCreated !== _dateCreated) {
        if (_dateCreated) {
          pgVersionManager.setDateCreated(_dateCreated);
          if (gallerySettings) {
            const _gallerySettings = JSON.parse(gallerySettings);
            const galleryUpgrades = _gallerySettings.upgrades;
            pgVersionManager.update(null, galleryUpgrades);
          }
          if (utils.isDev()) {
            window.dateCreated = dateCreated;
          }
        }
      }
    } catch (e) {
      console.error('Could not update version manager', e);
    }
  }

  calcPgTimings() {
    // this.pgTiming.wrapperRenderEnd = Date.now();
    const pgTiming = { ...this.pgTiming };
    let pgTableStr = Object.entries(pgTiming);

    pgTableStr = pgTableStr.sort((e1, e2) => e1[0] - e2[0]);
    pgTableStr = pgTableStr.map((e, idx, entries) => {
      if (idx > 0) {
        return { [e[1]]: e[0] - entries[idx - 1][0] };
      } else {
        return { [e[1]]: 0 };
      }
    });
    pgTableStr = pgTableStr.map(
      e =>
        Object.entries(e).map(_e => ({
          label: _e[0],
          millis: Number(_e[1]),
        }))[0],
    );
    pgTableStr = pgTableStr.sort((e1, e2) => e1.millis - e2.millis);
    return pgTableStr;
  }

  isMobileDevice(formFactor) {
    //santa options: mobile/desktop
    //bolt options: smartphone/desktop/tablet
    const deviceType = String(formFactor).toLowerCase();
    return deviceType === 'mobile' || deviceType === 'smartphone';
  }

  render() {
    const { queryParams, notInView } = this.props;

    // this.pgTiming.wrapperRenderStart = Date.now();
    this.pgTiming[Date.now()] = 'Wrapper Render Start';
    if (
      window.isSSR &&
      ((experiments && experiments['specs.pro-gallery.skipSsr'] === 'true') ||
        (queryParams && queryParams.skipPgSsr === 'true'))
    ) {
      console.error('Skipping Pro Gallery SSR!', this.props);
      return <div />;
    }

    if (utils.isSSR() && notInView) {
      if (utils.isVerbose()) {
        console.log('PG not in view, skipping');
      }
      return <div id="pg-not-in-view" />;
    }

    if (utils.isVerbose()) {
      console.log('Pro Gallery wrapper!', this.props);
      console.count('[OOISSR] proGallery ooi wrapper render');
    }
    const {
      id,
      dimensions: { width, height },
      style,
      formFactor,
      forceHover,
      setHeight,
      manualStyleParams,
    } = this.props;

    const setHeightImp = newHeight => {
      if (typeof setHeight === 'function') {
        if (utils.isVerbose()) {
          console.log('Setting new height for gallery', newHeight);
        }
        setHeight(newHeight);
      }
    };

    const handleNewGalleryStructure = ({
      numOfItems,
      container,
      partialStyleParams,
      layoutHeight,
      isInfinite,
    }) => {
      TPADimensionsHelper.setWixHeight({
        height: layoutHeight,
        offsetTop: 0,
        partialStyleParams,
        container,
        numOfItems,
        isInfinite,
        setHeightImp: setHeightImp,
      });
    };

    const isMobile = this.isMobileDevice(formFactor) || utils.isMobile();
    const deviceType = isMobile ? 'mobile' : 'desktop';

    const { styleParams } = style;
    const pgGalleryStyles = getProGalleryStyles(
      { ...styleParams, ...manualStyleParams },
      { isMobile },
    );

    if (
      isMobile &&
      !window.isSSR &&
      typeof pgGalleryStyles.gallerySize === 'number'
    ) {
      //compensate for wix's 320px fix for mobile
      const fixRatio = 320 / window.screen.width;
      pgGalleryStyles.gallerySize *= fixRatio;
      pgGalleryStyles.imageMargin *= fixRatio;
    }

    const pgItemsProps = () => {
      let items, totalItemsCount, getMoreItems;

      if (!this.props.wixCodeItems) {
        items = this.props.items || [];
        totalItemsCount = this.props.totalItemsCount || items.length || 1;
        getMoreItems = this.props.getMoreItems || (() => {});
      } else {
        items = this.props.wixCodeItems.map(item => {
          if (typeof item.metaData === 'string') {
            try {
              const newItem = {
                ...item,
                metaData: JSON.parse(item.metaData),
              };
              return newItem;
            } catch (e) {
              console.error('Failed parse item metaData', e);
            }
          }
          return item;
        });
        totalItemsCount = items.length;
        getMoreItems = () => {};
      }
      return {
        items,
        totalItemsCount,
        getMoreItems,
      };
    };

    const pgProps = {
      domId: id,
      useRefactoredProGallery: true,
      allowSSR: true,
      container: { width, height },
      forceHover,
      handleNewGalleryStructure,
      scrollingElement: this.getScrollingElement(),
      ...pgItemsProps(),
    };

    const pgContextValue = { isMobile };
    const dom = [
      <PgContext.Provider key="provider" value={pgContextValue}>
        <ProGallery
          key="pro-gallery"
          styles={pgGalleryStyles}
          onItemClicked={item => this.onItemClicked(item, pgGalleryStyles)}
          onAppLoaded={this.onAppLoaded}
          {...pgProps}
        />
      </PgContext.Provider>,
    ];

    if (!utils.isSSR()) {
      const LoadableFullscreenWrapper = Loadable({
        loader: () => import('../FullscreenWrapper/FullscreenWrapper'),
        loading() {
          return null;
        },
      });

      dom.push(
        <LoadableFullscreenWrapper
          {...pgProps}
          key="pro-fullscreen"
          styleParams={parseStyleParams(styleParams)}
          scrollTo={this.props.scrollTo}
          fullscreenAnimating={this.state.fullscreenAnimating}
          fullscreenIdx={this.state.fullscreenIdx}
          closeFullscreen={this.animatedCloseFullscreen}
          onLinkNavigation={this.props.onItemClicked}
          blockScroll={this.props.blockScroll}
          unblockScroll={this.props.unblockScroll}
        />,
      );
    }

    try {
      if (window.isSSR) {
        const pgTableStr = this.calcPgTimings();
        const pgTimingStr = Object.entries(this.pgTiming).map(e =>
          e.join(': '),
        );

        dom.push(
          <div key="pg-render-time" style={{ display: 'none' }}>
            <p>Pro Gallery Timing:</p>
            <ol>
              {pgTimingStr.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ol>
            <ol>
              {pgTableStr.map((e, i) => (
                <li key={i}>{`${e.label}: ${e.millis}`}</li>
              ))}
            </ol>
            <p>
              Device Type:
              <ol>
                <li>this.props.formFactor: {this.props.formFactor}</li>
                <li>formFactor: {formFactor}</li>
                <li>deviceType: {deviceType}</li>
                <li>isMobile: {isMobile}</li>
                <li>utils.isMobile(): {String(utils.isMobile())}</li>
                <li>utils.isWixMobile(): {String(utils.isWixMobile())}</li>
                <li>window.deviceType: {window.deviceType}</li>
              </ol>
            </p>
            <p>
              Query Params:
              {Object.entries(queryParams)
                .map(entry => entry.join(':'))
                .join(', ')}
            </p>
          </div>,
        );
      }
    } catch (e) {
      //
    }

    return (
      <div data-key={`gallery-wrapper-${id}`} key={`gallery-wrapper-${id}`}>
        {dom}
      </div>
    );
  }
}
